import handleLogout from "handlers/logout";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { ReduxState } from "types";
import { isInvestorStore, userId } from "utils/auth";

interface InvestorStoreAccessProps {
  children: React.ReactElement;
}

const InvestorStoreAccess = ({ children }: InvestorStoreAccessProps) => {
  const user = useSelector((state: ReduxState) => state?.user);

  const router = useRouter();

  const accessibleRoute = [
    '/login',
    '/dashboard/analytics/overall', 
    '/profile', 
    '/clout-updates',
    '/link-shopify',
  ];

  useEffect(() => {
    if (user?.storesFetched && user?.shopifyStoreData?.isInvestorStore !== isInvestorStore()) return userId() && handleLogout(router);

    if (isInvestorStore() && !accessibleRoute.some(route => router.pathname.startsWith(route))) {
      window.location.replace('/dashboard/analytics/overall');
    }
  }, [user?.storesFetched, router]);

  return <>{children}</>;
}

export default InvestorStoreAccess;
